import type { PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

type LinkType = 'logo' | 'login' | 'register' | 'hero_banner' | 'welcome_bonus';

type Links = {
  [k in LinkType]: string;
};

export const LinksContext = createContext<Links | null>(null);

export const LinksProvider = ({ children }: PropsWithChildren) => {
  const [links, setLinks] = useState<Links | null>(null);

  useEffect(() => {
    fetch('/links.json')
      .then((links) => links.json())
      .then(setLinks);
  }, []);

  if (!links) return null;

  return (
    <LinksContext.Provider value={links}>{children}</LinksContext.Provider>
  );
};

export const useLinks = () => useContext(LinksContext);
